export const MESSAGES = [
  {
    long: false,
    image: "/images/sanu/1_elegant.JPG",
    text: "/images/message/1.png",
  },
  {
    long: true,
    image: "/images/sanu/2_blessing.jpg",
    text: "/images/message/2.png",
  },
  {
    long: false,
    image: "/images/sanu/3_heart_my_pc.jpg",
    text: "/images/message/3.png",
  },
  {
    long: true,
    image: "/images/sanu/4_us_heart.jpg",
    text: "/images/message/4.png",
  },
  {
    long: true,
    image: "/images/sanu/5_sleep_5.jpg",
    text: "/images/message/5.png",
  },
  {
    long: true,
    image: "/images/sanu/6_sleep_2.jpg",
    text: "/images/message/6.png",
  },
  {
    long: true,
    image: "/images/sanu/7_eyes__.jpg",
    text: "/images/message/7.png",
  },
  {
    long: true,
    image: "/images/sanu/8___on_you.jpg",
    text: "/images/message/8.png",
  },
  {
    long: true,
    image: "/images/sanu/9_no_suyog.jpg",
    text: "/images/message/9.png",
  },
  {
    long: true,
    image: "/images/sanu/10_yes_suyog.jpg",
    text: "/images/message/10.png",
  },
  {
    long: false,
    image: "/images/sanu/11_with_topi.jpg",
    text: "/images/message/11.png",
  },
  {
    long: true,
    image: "/images/sanu/12_smile.jpg",
    text: "/images/message/12.png",
  },
  {
    long: false,
    image: "/images/sanu/13_sanu_tika.jpg",
    text: "/images/message/13.png",
  },
  {
    long: false,
    image: "/images/sanu/14_sanu_with_wine.jpg",
    text: "/images/message/14.png",
  },
  {
    long: true,
    image: "/images/sanu/15_us_with_tika.jpg",
    text: "/images/message/15.png",
  },
  {
    long: false,
    image: "/images/sanu/16_vaseline_balance.jpg",
    text: "/images/message/16.png",
  },
  {
    long: true,
    image: "/images/sanu/17_sanu_annoyed.jpg",
    text: "/images/message/17.png",
  },
  {
    long: false,
    image: "/images/sanu/18_b_and_white.jpg",
    text: "/images/message/18.png",
  },
  {
    long: false,
    image: "/images/sanu/18_mirror_selfie.jpg",
    text: "/images/message/19.png",
  },
  {
    long: false,
    image: "/images/sanu/19_my_cute_bunny.jpg",
    text: "/images/message/20.png",
  },
  {
    long: true,
    image: "/images/sanu/20_sexy.jpg",
    text: "/images/message/21.png",
  },
  {
    long: false,
    image: "/images/sanu/21_my_dp_of_sanu.jpg",
    text: "/images/message/22.png",
  },
  {
    long: false,
    image: "/images/sanu/22_us_main.JPG",
    text: "/images/message/23.png",
  },
  {
    long: true,
    image: "/images/sanu/23_sunshine.jpg",
    text: "/images/message/24.png",
  },
  {
    long: true,
    image: "/images/sanu/24_sleep_1.jpg",
    text: "/images/message/25.png",
  },
  {
    long: true,
    image: "/images/sanu/25_love_of_my_life.jpg",
    text: "/images/message/26.png",
  },
  {
    long: true,
    image: "/images/sanu/26_laugh.jpg",
    text: "/images/message/27.png",
  },
  {
    long: false,
    image: "/images/sanu/27_together.jpg",
    text: "/images/message/28.png",
  },
  {
    long: false,
    image: "/images/sanu/28_sanu_final_pic.JPG",
    text: "/images/message/hbd.png",
  },
];
