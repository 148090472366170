import React from "react";

const ImageFrame = ({ image, long = false, textHover = false }) => {
  if (!long) {
    return (
      <img
        style={{
          border: "20px solid #fff",
          boxShadow: "3px 3px 3px #777",
          width: "80%",
          // aspectRatio: "1 / 1",
          margin: "30px",
          background: "#E7E3DB",
          borderRadius: "6px",
        }}
        src={image}
        alt="Mero mutu"
      />
    );
  }
  return (
    <img
      style={{
        transition: "width 1s ease-in-out",
        boxShadow: "inset 3px 3px 3px #777",
        height: "100%",
        width: textHover ? "50%" : "73.6%",
        objectFit: textHover ? "cover" : "",
        aspectRatio: textHover ? "" : "221 / 480",
        background: "#E7E3DB",
        borderTopLeftRadius: "17px",
        borderBottomLeftRadius: "17px",
      }}
      src={image}
      alt="Mero mutu"
    />
  );
};

export default ImageFrame;
