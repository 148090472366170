export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width: width - 20,
    height: height - 100,
  };
};

export const calculatePageDimensions = (width, height) => {
  const aspectRatio = 5 / 8;

  if (height <= width * aspectRatio) {
    return { width: parseInt(height * aspectRatio), height };
  } else {
    let _width = height * aspectRatio;
    if (_width <= width) {
      return { width: _width, height };
    }
    return { width, height: parseInt(width / aspectRatio) };
  }
};
