import { useEffect, useState } from "react";
import "./App.css";
import FlipPage from "react-flip-page";
import Page from "./components/Page";
import { calculatePageDimensions, getWindowDimensions } from "./utils";
import { MESSAGES } from "./constants";
import loadingIcon from "./assets/loading.gif";
import axios from "axios";

function App() {
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(null);
  const [dims, setDims] = useState({ width: 0, height: 0 });
  const [loadCount, setLoadCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [password, setPassword] = useState("");
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio("/audio.mp3"));

  useEffect(() => {
    let dimensions = getWindowDimensions();
    setDims(calculatePageDimensions(dimensions.width, dimensions.height));
  }, []);

  const playAudio = () => {
    audio.play();
    setPlaying(true);
  };

  const pauseAudio = () => {
    audio.pause();
    setPlaying(false);
  };

  const musicControl = () => {
    if (playing) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const nextPage = () => {
    if (MESSAGES.length - 1 > currentPage) {
      setCurrentPage(currentPage + 1);
    }
    page.gotoNextPage();
  };
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    page.gotoPreviousPage();
  };

  const checkPassword = () => {
    audio.muted = false;
    setAudio(audio);
    if (!password.length) {
      setErrorText("Password is required");
    }
    setLoading(true);
    axios
      .post("https://9lr16q1m7i.execute-api.ap-south-1.amazonaws.com/gg", {
        password: password,
      })
      .then((res) => {
        if (res?.data?.statusCode === 401) {
          setErrorText("Wrong password");
          setLoading(false);
        } else {
          localStorage.setItem("token", res.data.token);
          setLoggedIn(true);
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setErrorText("Wrong password");
          setLoading(false);
        } else {
          setErrorText("Something went wrong");
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    let _loadCount = 0;
    let essential = ["/background.jpg", "/paper_reverse.jpg"];
    essential.map((m) => {
      axios.get(m).then((_) => {
        _loadCount += 1;
        setLoadCount(_loadCount);
      });
    });
  }, []);

  useEffect(() => {
    if (loadCount === 2) {
      setLoading(false);
    }
  }, [loadCount]);

  useEffect(() => {
    if (localStorage.getItem("token") && !loggedIn) {
      setLoading(true);
      axios
        .post("https://9lr16q1m7i.execute-api.ap-south-1.amazonaws.com/gg", {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res?.data?.statusCode === 401) {
            setErrorText("Wrong password");
            localStorage.removeItem("token");
            setLoggedIn(false);
            setLoading(false);
          } else {
            setTimeout(() => {
              setLoggedIn(true);
              setLoading(false);
            }, 5000);
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          setLoggedIn(false);
          setLoading(false);
        });
    }
  }, [loading]);

  if (localStorage.getItem("token") && !loggedIn) {
    // setLoading(true);
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          width: "100hw",
          height: "100vh",
          background: "black",
        }}
      >
        <img style={{ width: "20%" }} src={loadingIcon} alt="loading..." />
        <h1 style={{ color: "white" }}>Loading...</h1>
      </div>
    );
  }

  if (!loggedIn) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          width: "100hw",
          height: "100vh",
          background: "black",
        }}
      >
        <input
          autoComplete="off"
          type="password"
          value={password}
          placeholder="Enter password"
          className="inputText"
          onChange={(e) => setPassword(e.target.value)}
        />
        {errorText.length ? (
          <p
            style={{
              color: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {errorText}
          </p>
        ) : (
          ""
        )}
        <button className="button" onClick={checkPassword}>
          ENTER
        </button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        width: "100hw",
        height: "100vh",
        // background:
        //   "radial-gradient(circle at center, #fdfbfb 0%, #ebedee 100%)",
        backgroundImage: "url(/background.jpg)",
        backgroundSize: "cover",
      }}
    >
      <FlipPage
        ref={(component) => {
          setPage(component);
        }}
        orientation="horizontal"
        pageBackground="rgba(0, 0, 0, 0.0)"
        width={dims.width}
        height={dims.height}
        uncutPages={true}
        animationDuration={500}
        style={{
          boxShadow: "7px 7px 7px #000",
          borderRadius: "17px",
          margin: "10px",
        }}
      >
        {MESSAGES.map((message, index) => {
          return (
            <Page
              key={index}
              image={message.image}
              text={message.text}
              long={message.long}
            />
          );
        })}
      </FlipPage>
      <div
        style={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button className="buttonNav" onClick={previousPage}>
          Previous
        </button>
        <button className="buttonNav" onClick={musicControl}>
          {playing ? "MUTE" : "UNMUTE"}
        </button>
        <button className="buttonNav" onClick={nextPage}>
          Next
        </button>
      </div>
    </div>
  );
}

export default App;
