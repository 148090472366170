import React, { useEffect, useState } from "react";
import { getWindowDimensions, calculatePageDimensions } from "../../utils";
import ImageFrame from "../ImageFrame";

const Page = ({ image, text, long = false }) => {
  const [dims, setDims] = useState({ width: 0, height: 0 });
  const [hover, setHover] = useState(false);

  useEffect(() => {
    let dimensions = getWindowDimensions();
    setDims(calculatePageDimensions(dimensions.width, dimensions.height));
  }, []);

  const hoverStart = () => {
    setHover(true);
  };
  const hoverEnd = () => {
    setHover(false);
  };

  return (
    <div
      className="main-paper"
      style={{
        width: dims.width,
        height: dims.height,
        boxShadow: "7px 7px 7px #000",
        borderRadius: "17px",
        display: "flex",
        flexDirection: long ? "row" : "column",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundImage: "url(/paper_reverse.jpg)",
        backgroundSize: "cover",
      }}
    >
      <ImageFrame image={image} long={long} textHover={hover} />
      <div
        onMouseEnter={hoverStart}
        onMouseLeave={hoverEnd}
        style={{
          transition: "width 1s ease-in-out",
          boxShadow: long ? "-7px 0 7px -2px #000" : "",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
          width: long ? "50%" : "100%",
          backgroundSize: "contain",
        }}
      >
        {/* <h1>{text}</h1> */}
        <img
          style={{ maxWidth: "90%", maxHeight: "100%" }}
          src={text}
          alt="sign"
        />
      </div>
    </div>
  );
};

export default Page;
